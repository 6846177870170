export const contactsData = {
    email: 'karolisgied@gmail.com',
    phone: '+37060961813',
    address: 'Kaunas, Lithuania, Kumelių g. 17-1',
    github: 'https://github.com/karolis15',
    facebook: 'https://www.facebook.com/karolis.giedraitis.568',
    linkedIn: 'https://www.linkedin.com/in/karolis-giedraitis-752664280/',
    hackerrank: 'https://www.hackerrank.com/profile/karolisgied',
    devUsername: "Karolis"
}
