import eight from '../assets/svg/projects/eight.svg'
import seven from '../assets/svg/projects/seven.svg'
import six from '../assets/svg/projects/six.svg'
import tablethumbnail from '../assets/svg/projects/tablethumbnail1.svg'



export const projectsData = [
    {
        id: 1,
        projectName: 'Web Component for IZUM Internship',
        projectDesc: 'One of the web components I made working for IZUM during my internship.',
        tags: ['Web Component', 'Internship'],
        code: 'https://github.com/karolis15/Quiz-web-component',
        demo: 'https://karolis15.github.io/',
        image: tablethumbnail
    }
]



// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/